import React from "react";
import EvidenceMapLogo from "../images/evidence_map_logo.svg";
import WelshGovLogo from "../images/welsh_gov_logo_black.svg";
import "../css/bootstrap.min.css";

const Header = () => {
	return (
		<>
			<div
				className="container"
				style={{ marginTop: "1rem", textAlign: "center" }}
			>
				<div
					className=" row"
					style={{ width: "inherit", margin: "0 auto" }}
				>
					<div
						className="logo-container"
						style={{ width: "50%", textAlign: "left" }}
					>
						<a
							href="https://cultureevidencereview.evidencemap.com/"
							style={{ display: "flex" }}
						>
							<img
								style={{ maxHeight: 70, marginRight: "auto" }}
								src={EvidenceMapLogo}
								alt="Evidence Map logo"
							/>
						</a>
					</div>
					<div
						className="header-navigation"
						style={{
							width: "50%",
							textAlign: "right",
							margin: "auto",
						}}
					>
						<a
							href="https://www.gov.wales/"
							target="_blank"
							rel="noopener noreferrer"
							style={{ display: "flex" }}
						>
							<img
								id="client_logo"
								src={WelshGovLogo}
								alt="WelshGovLogo"
								className="img-fluid"
								style={{
									maxHeight: 75,
									maxWidth: 240,
									marginLeft: "auto",
								}}
							/>
						</a>
					</div>
				</div>
			</div>
			<div className="container" id="header">
				<h1
					style={{
						textAlign: "center",
						marginBottom: "2rem",
						marginTop: "2rem",
					}}
				>
					<a
						href="https://cultureevidencereview.evidencemap.com/"
						className="em-title"
					>
						Review of the Culture Sector Evidence Base in Wales
					</a>
				</h1>
			</div>
		</>
	);
};

export default Header;
